.App {
  text-align: center;
}

.App-logo {
  height: 32vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 1.25vmin);
  color: white;
}

.App-link {
  color: #bc93f9;
}

@media screen and (max-width: 575px) {
}

@media screen and (min-width: 576px) and (max-width: 991px) {
}
@media screen and (min-width: 992px) and (max-width: 1180px) {
}

@media screen and (min-width: 1181px) {
}
