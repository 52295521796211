@import '../../../css/common-page-alignments/styles.css';
@import '../../../css/buttons/styles.css';
@import '../../../css/helpers/styles.css';

.info-section-phase-dashboard {
  font-size: 16px;
}

.dashboard-page-main-container {
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: start;
}

.dashboard-page-main-container .page-heading-title {
  height: 100px;
}

.dashboard-page-main-container .principal-content-profile-page {
  margin: 0;
  margin-bottom: 60px;
  width: 100%;
}

.info-container-dashboard-page {
  width: 470px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border: 2px solid #f9b11c;
  box-shadow:
    1px 1px 4px #ed693c,
    -1px -1px 4px #fdc60b;
}

.footer-join-button-container {
  height: 50px;
  margin-top: -25px;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.margin-top-10 {
  margin-top: -10px;
}

.dashboard-page-main-container .footer-join-button-container button {
  width: 250px !important;
}

@media screen and (max-width: 575px) {
  /* the container with the information */
  .dashboard-page-main-container .main-info-container-normal-user {
    /* width: 90%; */
  }

  /* the sections I'm displaying in the main container */
  .dashboard-page-main-container .content-sections-title-info-container span {
    font-size: 14px;
  }

  /* the result of the section above */
  .dashboard-page-main-container .title-info-container {
    font-size: 14px;
  }

  /* the result of the section above */
  .result-of-content-section {
    font-size: 8.6px;
  }

  .info-section-phase-dashboard {
    font-size: 14px;
  }
}

@media screen and (min-width: 576px) and (max-width: 991px) {
  .dashboard-page-main-container .page-heading-title {
    height: 120px;
  }

  .dashboard-page-main-container .page-heading-title h2 {
    font-size: 30px;
  }

  .dashboard-page-main-container .main-info-container-normal-user {
    margin-top: 80px;
  }

  .dashboard-page-main-container .info-container-dashboard-page {
    /* width: 500px; */
    width: 90%;
  }

  .dashboard-page-main-container .title-info-container {
    font-size: 20px;
  }

  .dashboard-page-main-container .content-sections-title-info-container span {
    font-size: 22px;
  }

  .result-of-content-section {
    font-size: 15.5px;
  }

  .info-container-dashboard-page .footer-join-button-container {
    margin-top: -15px;
    margin-bottom: 10px;
  }

  .info-container-dashboard-page .footer-join-button-container button {
    font-size: 20px;
    padding-block: 15px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1180px) {
  .dashboard-page-main-container .main-info-container-normal-user {
    margin-top: 90px;
  }

  .dashboard-page-main-container .page-heading-title h2 {
    font-size: 28px;
  }

  .dashboard-page-main-container .content-sections-title-info-container span,
  .dashboard-page-main-container .title-info-container,
  .result-of-content-section {
    font-size: 18px;
  }

  .dashboard-page-main-container .info-container-dashboard-page {
    width: 90%;
  }
}

@media screen and (min-width: 1181px) {
  .dashboard-page-main-container .main-info-container-normal-user {
    margin-top: 90px;
  }

  .dashboard-page-main-container .page-heading-title h2 {
    font-size: 28px;
  }

  .dashboard-page-main-container .content-sections-title-info-container span,
  .dashboard-page-main-container .title-info-container,
  .result-of-content-section {
    font-size: 20px;
  }

  .dashboard-page-main-container .info-container-dashboard-page {
    /* width: 900px; */
    width: 90%;
  }
}
