.custom-input {
  background-color: #f9f9f9;
  border: 1px solid grey;
  border-radius: 5px;
  width: 100% !important;
  color: #2f2f2f;
}

.custom-input:focus {
  outline: none;
  border: 0.5px solid #ed693c;
  box-shadow:
    1px 1px 4px #fdc60b,
    -1px -1px 4px #fdc60b;
}
