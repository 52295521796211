body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

button.Connect,
button.Vote {
  color: #fff;
  font-size: 18px;
  font-weight: bold;
}

button.Connect:hover,
button.Vote:hover {
  filter: brightness(1.35);
}

button.Connect {
  border: 0;
  border-radius: 0;
  font-size: 1;
  padding-top: 5px;
  padding-right: 7px;
}

button.Vote {
  background-color: rgba(148, 48, 148, 0.2);
  border: 2px solid rgb(148, 48, 148);
  border-radius: 14px;
  padding: 8px 12px;
  margin: 4px;
}
