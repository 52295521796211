@import '../../../css/inputs/styles.css';
@import '../../../css/buttons/styles.css';

.info-icon-profile-page-stacking {
  font-size: 15px !important;
}

.yellow-icon {
  color: #f9b11c !important;
}

.info-container-stacking-profile-page {
  background-color: #f9b11c;
  width: 470px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border: 2px solid #f9b11c;
  box-shadow:
    1px 1px 4px #ed693c,
    -1px -1px 4px #fdc60b;
}

.content-info-container-stacking {
  padding: 10px 20px 20px 20px;
  background-color: #f9b11c;
  background-color: #fef9f7;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 300px;
}

.intro-container-stacking-profile-page {
  height: 60px;
  border-radius: 3px;
  padding: 10px;
  border: 1px solid;
  border-left: #fdc60b;
  border-right: #ed693c;
  border-top: #fdc60b;
  border-bottom: #ed693c;
  box-shadow:
    1px 1px 4px #ed693c,
    -1px -1px 4px #fdc60b;
  position: relative;
  width: 85%;
  margin-inline: auto;
}

.intro-icon-container {
  border-radius: 10% 10% 30% 30% / 10% 10% 45% 45%;
  box-shadow:
    1px 1px 4px #ed693c,
    -1px -1px 4px #ed693c;
  background-color: #fdc60b;
  height: 100px;
  width: 100px;
  position: absolute;
  top: -60px;
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.role-icon {
  height: 90% !important;
  width: 90% !important;
}

.intro-informations-profile-page {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.main-info-container-stacking {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin-inline: auto;
}

.main-info-container-stacking-normal-user {
  margin-top: 40px;
  display: flex;
  justify-content: center;
  width: 80%;
  margin-inline: auto;
}

.principal-content-profile-page {
  margin-top: 90px;
  width: 90%;
  margin-inline: auto;
}

.page-heading-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.page-heading-title h2 {
  margin-bottom: 0;
}

.intro-sides {
  align-items: center;
  height: 100% !important;
  display: flex;
  flex-direction: column;
  width: 165px;
}

.intro-center-side {
  align-items: end;
  height: 100% !important;
  display: flex;
}

.footer-button-container-stacking {
  height: 50px;
  margin-top: 5px;
  margin-bottom: -15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#revoke-delegate-button button {
  width: 270px !important;
}

.profile-page-main-container-stacking {
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: start;
}

.profile-page-main-container-stacking .page-heading-title {
  height: 100px;
}

.profile-page-main-container-stacking .principal-content-profile-page {
  width: 100%;
}

.about-section-phase-info {
  font-size: 16px;
}

@media screen and (max-width: 575px) {
  /* the introduction container-stacking (the orange rectangle)  */
  .profile-page-main-container-stacking .intro-container-stacking-profile-page {
    width: 90%;
  }

  /* the container-stacking which hold the about and actions containers */
  .profile-page-main-container-stacking .main-info-container-stacking {
    width: 89%;
    flex-direction: column;
    margin-inline: auto;
    justify-content: start;
    align-items: center;
    margin-top: 30px;
  }

  /* the about and actions containers */
  .profile-page-main-container-stacking .info-container-stacking-profile-page {
    width: 100%;
    margin-bottom: 30px;
  }

  /* profile page intro lateral information */
  .profile-page-main-container-stacking .intro-sides {
    font-size: 13px;
  }

  /* profile page intro central information (current role) */
  .profile-page-main-container-stacking .intro-center-side {
    font-size: 15px;
  }

  .profile-page-main-container-stacking .intro-icon-container {
    width: 90px;
    height: 90px;
  }

  /* about and actions heading title */
  .profile-page-main-container-stacking .title-info-container-stacking {
    font-size: 14px;
  }

  /* the about and actions containers */
  .profile-page-main-container-stacking .content-info-container-stacking {
    height: auto;
  }

  /* the sections I'm displaying in the main container-stacking */
  .profile-page-main-container-stacking .content-sections-title-info-container-stacking span {
    font-size: 14px;
  }

  /* the result of the section above */
  .result-of-content-section {
    font-size: 12px;
  }

  .result-of-content-section.connected-walled {
    font-size: 8.6px !important;
  }

  .current-block,
  .about-section-phase-info {
    font-size: 14px;
  }

  /* autoexchange button container-stacking */
  .profile-page-main-container-stacking .autoexchange-button-container-stacking {
    width: 80%;
    margin-inline: auto;
    margin-top: 10px;
  }

  /* autoexchange button from about container-stacking on profile page */
  .profile-page-main-container-stacking .autoexchange-button-container-stacking button {
    font-size: 14px;
  }

  /*every input container-stacking in the actions container-stacking on profile page */

  .main-info-container-stacking
    .info-container-stacking-profile-page
    .content-info-container-stacking
    .input-line-actions-container-stacking {
    display: flex;
    flex-direction: column !important;
  }

  .main-info-container-stacking .info-container-stacking-profile-page .width-55 {
    width: 80% !important;
  }

  /* the label and input container-stacking from actions container-stacking on profile page */
  .input-line-actions-container-stacking .label-and-input-container-stacking-actions-container-stacking {
    width: 100% !important;
  }

  /* the input line from actions container-stacking on profile page */
  .custom-input {
    box-sizing: border-box;
    height: 38px;
  }

  /* the input button container-stacking from actions container-stacking on profile page */
  .button-container-stacking-action-container-stacking {
    width: 80%;
  }

  /* the input button from actions container-stacking on profile page */
  .button-container-stacking-action-container-stacking button {
    width: 100%;
    font-size: 14px;
  }

  /* leave pool button container-stacking from actions container-stacking */
  .leave-pool-button-action-container-stacking {
    width: 65%;
    margin-inline: auto;
  }

  /* the leave pool button */
  .leave-pool-button-action-container-stacking button {
    font-size: 14px !important;
  }

  /* the alignment of the leave pool button */
  .leave-pool-button-action-container-stacking div.flex-right {
    align-items: center;
    margin-top: 20px;
  }

  /* the alert container-stacking when clicking the leave pool button when you are the notifier */
  .alert-container-stacking-actions-container-stacking {
    margin-top: 20px !important;
  }
}

@media screen and (min-width: 576px) and (max-width: 991px) {
  .profile-page-main-container-stacking .page-heading-title {
    height: 120px;
  }

  .profile-page-main-container-stacking .page-heading-title h2 {
    font-size: 30px;
  }

  .profile-page-main-container-stacking .main-info-container-stacking {
    width: 89%;
    flex-direction: column;
    margin-inline: auto;
    justify-content: start;
    align-items: center;
    margin-top: 60px;
  }

  .profile-page-main-container-stacking .info-container-stacking-profile-page {
    width: 80%;
    margin-bottom: 60px;
    height: auto !important;
  }

  .profile-page-main-container-stacking .title-info-container-stacking {
    font-size: 20px;
  }

  .profile-page-main-container-stacking .content-sections-title-info-container-stacking span {
    font-size: 22px;
  }

  .profile-page-main-container-stacking .content-sections-title-info-container-stacking .result-of-content-section {
    font-size: 18px;
  }

  /* profile page intro lateral information */
  .profile-page-main-container-stacking .intro-sides {
    font-size: 16px;
  }

  /* profile page intro central information (current role) */
  .profile-page-main-container-stacking .intro-center-side {
    font-size: 22px;
  }

  /* autoexchange button container-stacking */
  .profile-page-main-container-stacking .autoexchange-button-container-stacking {
    width: 80%;
    margin-inline: auto;
    margin-top: 10px;
  }

  .leave-pool-button-action-container-stacking {
    margin-top: 20px;
  }

  .leave-pool-button-action-container-stacking div.flex-right {
    align-items: center;
  }

  .leave-pool-button-action-container-stacking button {
    width: 300px;
    font-size: 20px;
    height: 45px;
  }

  /* autoexchange button from about container-stacking on profile page */
  .profile-page-main-container-stacking .autoexchange-button-container-stacking button,
  .button-container-stacking-action-container-stacking button {
    font-size: 20px;
    width: 300px;
    height: 45px;
  }

  .button-container-stacking-action-container-stacking button {
    margin-bottom: 5px;
  }

  /* the input line from actions container-stacking on profile page */
  .custom-input {
    box-sizing: border-box;
    height: 45px;
  }

  .input-line-actions-container-stacking {
    /* align-items: end !important; */
  }
  .alert-container-stacking-actions-container-stacking {
    margin-top: 20px !important;
  }

  .profile-page-main-container-stacking .content-info-container-stacking {
    height: auto;
  }

  /* .main-info-container-stacking .info-container-stacking-profile-page .width-55 {
    width: 50% !important;
  } */

  .main-info-container-stacking
    .info-container-stacking-profile-page
    .content-info-container-stacking
    .input-line-actions-container-stacking {
    display: flex;
    flex-direction: column !important;
  }

  .main-info-container-stacking .info-container-stacking-profile-page .width-55 {
    width: 80% !important;
  }
}

@media screen and (min-width: 992px) and (max-width: 1180px) {
  .profile-page-main-container-stacking .page-heading-title h2 {
    font-size: 28px;
  }

  /* profile page intro lateral information */
  .profile-page-main-container-stacking .intro-sides {
    font-size: 16px;
  }

  /* profile page intro central information (current role) */
  .profile-page-main-container-stacking .intro-center-side {
    font-size: 22px;
  }

  .profile-page-main-container-stacking .main-info-container-stacking {
    width: 89%;
    flex-direction: column;
    margin-inline: auto;
    justify-content: start;
    align-items: center;
    margin-top: 60px;
  }

  .profile-page-main-container-stacking .info-container-stacking-profile-page {
    width: 80%;
    margin-bottom: 60px;
    height: auto !important;
  }

  .profile-page-main-container-stacking .title-info-container-stacking {
    font-size: 20px;
  }

  .profile-page-main-container-stacking .content-sections-title-info-container-stacking span {
    font-size: 20px;
  }

  .profile-page-main-container-stacking .content-sections-title-info-container-stacking .result-of-content-section {
    font-size: 18px;
  }

  /* autoexchange button container-stacking */
  .profile-page-main-container-stacking .autoexchange-button-container-stacking {
    width: 70%;
    margin-inline: auto;
    margin-top: 10px;
  }

  .leave-pool-button-action-container-stacking {
    margin-top: 20px;
  }

  /* autoexchange button from about container-stacking on profile page */
  .profile-page-main-container-stacking .autoexchange-button-container-stacking button,
  .leave-pool-button-action-container-stacking button,
  .button-container-stacking-action-container-stacking button {
    font-size: 18px;
    width: 270px;
    height: 45px;
  }

  .button-container-stacking-action-container-stacking button {
    margin-bottom: 5px;
  }

  /* the input line from actions container-stacking on profile page */
  .custom-input {
    box-sizing: border-box;
    height: 45px;
  }

  .input-line-actions-container-stacking {
    align-items: end !important;
  }
  .alert-container-stacking-actions-container-stacking {
    margin-top: 20px !important;
  }

  .profile-page-main-container-stacking .content-info-container-stacking {
    height: auto;
  }

  .custom-label {
    font-size: 13px !important;
  }
}

@media screen and (min-width: 1181px) {
  .profile-page-main-container-stacking .intro-container-stacking-profile-page {
    width: 90%;
  }

  .profile-page-main-container-stacking .page-heading-title h2 {
    font-size: 28px;
  }

  /* profile page intro lateral information */
  .profile-page-main-container-stacking .intro-sides {
    font-size: 16px;
  }

  /* profile page intro central information (current role) */
  .profile-page-main-container-stacking .intro-center-side {
    font-size: 22px;
  }

  .profile-page-main-container-stacking .main-info-container-stacking {
    width: 89%;
    flex-direction: column;
    margin-inline: auto;
    justify-content: start;
    align-items: center;
    margin-top: 50px;
  }

  .profile-page-main-container-stacking .info-container-stacking-profile-page {
    width: 80%;
    margin-bottom: 50px;
    height: auto !important;
  }

  .profile-page-main-container-stacking .title-info-container-stacking {
    font-size: 20px;
  }

  .profile-page-main-container-stacking .content-sections-title-info-container-stacking span {
    font-size: 20px;
  }

  .profile-page-main-container-stacking .content-sections-title-info-container-stacking .result-of-content-section {
    font-size: 15px;
  }

  /* autoexchange button container-stacking */
  .profile-page-main-container-stacking .autoexchange-button-container-stacking {
    width: 170px;
  }

  .leave-pool-button-action-container-stacking {
    margin-top: 20px;
  }

  /* autoexchange button from about container-stacking on profile page */
  .profile-page-main-container-stacking .autoexchange-button-container-stacking button,
  .leave-pool-button-action-container-stacking button,
  .button-container-stacking-action-container-stacking button {
    font-size: 18px;
    width: 270px;
    height: 45px;
  }

  .button-container-stacking-action-container-stacking button {
    margin-bottom: 5px;
  }

  /* the input line from actions container-stacking on profile page */
  .custom-input {
    box-sizing: border-box;
    height: 45px;
  }

  .input-line-actions-container-stacking {
    align-items: end !important;
  }

  .profile-page-main-container-stacking .content-info-container-stacking {
    height: 100%;
  }

  .custom-label {
    font-size: 12px !important;
  }

  /* the alert container-stacking when clicking the leave pool button when you are the notifier */
  .alert-container-stacking-actions-container-stacking {
    margin-top: 20px !important;
  }
}
