.css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked {
  color: red !important;
  background-color: green !important;
}

/* loginButtonIconHeader */
/* button.Connect .css-i4bv87-MuiSvgIcon-root {
  color: #f9b11c !important;
} */

/* switch theme icon button light theme */
.css-decirt-MuiSwitch-root .MuiSwitch-thumb {
  background-color: #f9b11c !important;
}

/* switch theme icon button dark theme */
.css-1fq2vw5-MuiSwitch-root .MuiSwitch-thumb {
  background-color: #f9b11c !important;
}

/* open side bar button when it's closed  */

.MuiCardHeader-action .css-i4bv87-MuiSvgIcon-root {
  color: #f9b11c !important;
}

/* show the current section in the side bard menu */
.active-custom .css-i4bv87-MuiSvgIcon-root,
.active-custom .css-tlelie-MuiListItemText-root {
  color: #f9b11c !important;
}

/* open side bar button when it's open  */
#open-menu-icon {
  color: #f9b11c !important;
}

.default-page-container {
  height: 100vh;
  /* width: 100vw; - Do not add this, because if there's vertical scroll, the page will also have horizontal scroll. without it,
                     the page width will be automatically adjusted for the contents. You only need the height. */
}

.liMenuMiningPool {
  display: block !important;
}

.padding-left-sidebar-main-sections {
  padding-left: 35px !important;
}

.padding-left-sidebar-inner-sections {
  padding-left: 60px !important;
}

@media screen and (max-width: 575px) {
  .navbar-sections-font-size span {
    font-size: 14px !important;
  }
}

@media screen and (min-width: 576px) and (max-width: 991px) {
  .navbar-sections-font-size span {
    font-size: 16px !important;
  }
}
